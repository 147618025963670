
import React, { useState } from 'react'
import Chemicals from './chemical/chemicals';
import Hardware from './hardware/hardware';
import Technicians from './Technicians/Technicians';

import { Tabs as SettingsTabs } from '../components/Tabs/Tabs';

export default function Settings({children}) {
    
    
    const sectionTabs = [
        {
            title: 'Chemicals',
            identifier: 'CHEMICALS',
        },
        {
            title: 'Hardware',
            identifier: 'HARDWARE',
        },
        {
            title: 'Technicians',
            identifier: 'TECHNICIANS',
        },
    ];

    const [currentTab, setCurrentTab] = useState(sectionTabs[0]);

    const handleTabsClick = (identifier) => {
      // if (identifier === 'SUBSCRIPTIONS') {
      //     setCurrentTab(sectionTabs[0]);
      // } else 
      if (identifier === 'CHEMICALS') {
          setCurrentTab(sectionTabs[0]);
      } else if (identifier === 'HARDWARE') {
          setCurrentTab(sectionTabs[1]);
      } else {
          setCurrentTab(sectionTabs[2]);
      }
    }

    const settingTabOptions = {
        active: currentTab.identifier,
        fullWidth: true,
        tabs: [
            // { name: 'Subscriptions', identifier: 'SUBSCRIPTIONS' },
            { name: 'Chemicals', identifier: 'CHEMICALS' },
            // { name: 'Payment Info', identifier: 'PAYMENTINFO' },
            { name: 'Hardware', identifier: 'HARDWARE' },
            { name: 'Technicians', identifier: 'TECHNICIANS' },
        ],
        handleClick: handleTabsClick
    };
  
    
    return (
        <div>
                <div className="p-5 xl:w-4/5">
                    <h1 className='text-2xl font-bold p-3'>Settings</h1>
                    {children}
                    <SettingsTabs {...settingTabOptions} />
                </div>
                <div className="flex flex-col w-full xl:w-4/5 px-5">{
                    currentTab.identifier === "CHEMICALS" ? <Chemicals /> : currentTab.identifier === "HARDWARE" ? <Hardware /> : <Technicians />
                }</div>
        </div>
    )
}
