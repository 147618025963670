import React from 'react'

const ExampleTable = () => {
    return (
        <table className="md:min-w-full divide-y divide-gray-300 border">
            <thead className="bg-gray-50">
                <tr>
                    <th
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                        scope="col"
                    >
                        Client Name
                    </th>
                    <th
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                        scope="col"
                    >
                        Email
                    </th>
                    <th
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                        scope="col"
                    >
                        Address
                    </th>
                    <th
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                        scope="col"
                    >
                        Square Foot
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
                <tr className='relative'>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="text-gray-900 break-words">
                            Stephine
                        </div>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="text-gray-900 break-words">
                            stephine@gmail.com
                        </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                            3706 Meadowknolls Rd, Marion, IA 52302, USA
                        </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                            5000
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ExampleTable