import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { PrimaryButton } from '../../../components/Button/PrimaryButton';
import Stepper from './Stepper';
import { useNavigate } from "react-router-dom";
import { ClientsApi } from '../../../lib/clients.api';
import { useApiOperation } from "../../../hooks/useApiOperation";
import { NOTIFY_TYPE } from "../../../utils/constants";
import { useSelector } from 'react-redux';


const Step4 = () => {
    const [csvData, setCsvData] = useState([]);
    const { startApiOperation, terminateApiOperation } = useApiOperation();
    const currentStep = 4;
    const navigate = useNavigate();
  
    const step2Data = useSelector((state) => state.csvData.step2SelectedData);
    const selectedHeaders = useSelector((state) => state.csvData.step3ExcludedCols); 
  
    useEffect(() => {
      if (!step2Data.length) {
        navigate("/clients/select-header");
      }
  
      
      const reorderedData = step2Data.map((row) => {
        const reorderedRow = {};
        selectedHeaders.forEach(({ header }) => {
          reorderedRow[header] = row[header];
        });
        return reorderedRow;
      });
  
      setCsvData(reorderedData);
    }, [step2Data, selectedHeaders, navigate]);
    const handleNext = async () => {
        if (!csvData || csvData.length === 0) {
          terminateApiOperation(["Please upload a CSV file first."]);
          return;
        }
      
        try {
          startApiOperation();
      
          const transformedData = step2Data.map((row) => ({
            clientName: row.clientName,
            email: row.email,
            property: {
              address: row.address,
              squareFeet: row.squareFeet,
            },
          }));
      
          const response = await ClientsApi.bulkUploadClientData(transformedData);
      
          if (response && response.message) {
          const duplicateKeyCount = response.results.filter((result) =>
            result.message.includes("E11000 duplicate key")
          ).length;
          
          let fullMessage = `${response.message}`;
          if (duplicateKeyCount > 0) {
            fullMessage += `\n\nDetails:\n${duplicateKeyCount} duplicate key errors found: Property data already exists.`;
          }
          
          terminateApiOperation([fullMessage], NOTIFY_TYPE.Success);
          
          
          }
      
          navigate("/clients");
        } catch (error) {
          const errorMessage = error.response?.data?.message || "An unexpected error occurred.";
          terminateApiOperation([errorMessage], { type: "Error" });
        }
      };
      

    const handleBack = () => navigate("/clients/match-column");
    const TableHeader = () =>{
      if (!selectedHeaders) return null;
      return <>
      {selectedHeaders.map(({ header }, idx) => (
                      <th className="py-4 px-6 text-left" key={idx}>
                        {header}
                      </th>
                    ))}
      </>
    }
    const TableBody = () => {
      if (!csvData.length) return null;
      return <>
       {csvData.map((row, idx) => (
                    <tr key={idx}>
                      {selectedHeaders.map(({ header }, index) => (
                        <td className="py-4 px-6" key={index}>
                          {row[header]}
                        </td>
                      ))}
                    </tr>
                  ))}
      </>
    }
  
    return (
        <div className="p-5">
          <div className="flex justify-between">
            <PrimaryButton
              onClickEvent={handleBack}
              label={"Back"}
              icon={<ChevronLeftIcon className="w-4 h-4" />}
              classNames="gap-x-1 items-start"
            />
            <PrimaryButton
              onClickEvent={handleNext}
              label={"Upload"}
              classNames="gap-x-1 items-start"
            />
          </div>
          <Stepper currentStep={currentStep} />
          <div>
            <h2 className="text-2xl font-extrabold mb-6">Validate Information</h2>
            <div className="border rounded-lg mb-6 overflow-auto" style={{ maxHeight: "550px" }}>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-blue-50">
                  <tr>
                    <TableHeader/>
                  </tr>
                </thead>
                <tbody>
                  <TableBody/>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    );
  };
  
 export default Step4;