import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import AddHardware from '../../components/hardware/addHardware';
import { ListHardwares } from '../../components/hardware/renderCards';
import { Loader } from '../../components/Loader/Loader';
import { ErrorAlert } from '../../components/Popups/ErrorAlert';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Hardware = () => {
    const { apiLoading, startApiOperation, terminateApiOperation, apiError } = useApiOperation()
    const [selectedHardware, setSelectedHardware] = useState({})
    const [hardwaresData, setHardwaresData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    useEffect(() => {
        fetchHardwares();
    }, []);

    const fetchHardwares = async () => {
        try {
            startApiOperation()
            const responseData = await HardwaresApi.fetchHardwares();
            setHardwaresData(responseData)
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation(['Error fetching data'])
        }
    };

    const deleteSelectedHardwares = async () => {
        try {
            startApiOperation()
            const { _id } = selectedHardware
            await HardwaresApi.deleteHardware(_id);
            fetchHardwares();
            setDeleteModal(false)
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation([error])
        }
    }

    const DisplayLoader = () => {
        if (apiLoading) return <Loader />
    }

    const DisplayError = () => {
        if (apiError) return <ErrorAlert errorMessages={apiError} />
    }

    return (
        <div>
            <DisplayLoader />
            <DisplayError />
            <Elements stripe={stripePromise}>
                <AddHardware
                    refetch={fetchHardwares}
                />
            </Elements>
            <ListHardwares
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                editModal={editModal}
                setEditModal={setEditModal}
                hardwaresData={hardwaresData}
                deleteSelected={deleteSelectedHardwares}
                selectedHardware={selectedHardware}
                setSelectedHardware={setSelectedHardware}
                reFetch={fetchHardwares}
            />
        </div>
    );
}

export default Hardware 